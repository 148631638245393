#root {
    min-height: 100vh;
}

body {
    font-size: 16px;
    line-height: 24px;
    font-family: "Work Sans", sans-serif;
    color: #808996;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f7fc;
}

*:focus {
    outline: none;
}

a {
    text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
ol,
ul {
    margin: 0;
}

/* Container */
.container {
    max-width: 1200px;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

/* Video Modal */
.modal-video {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
}

.modal-video:before {
    content: "";
    background: #0b0b0b;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}

.modal-video .modal-video-body {
    height: 100%;
}

.modal-video .modal-video-inner {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.modal-video .modal-video-inner .modal-video-movie-wrap {
    padding: 0 !important;
    position: relative;
}

.modal-video .modal-video-inner .modal-video-movie-wrap iframe {
    width: 1100px;
    height: 600px;
}

.modal-video-close-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    border: none;
    background: #fff;
    transition: background-color 0.3s;
}

.modal-video-close-btn:hover {
    background: #ff6b6b;
}

.modal-video-close-btn:before {
    content: "x";
    font-size: 24px;
    font-weight: 600;
    transition: 0.3s;
}

.modal-video-close-btn:hover:before {
    color: white;
}

.icon-element-2 svg {
    width: 25px;
}

/* Write your own CSS Code Below */
.cluster-marker {
    text-align: center;
    font-size: 15px;
    background: #333f57;
    color: #fff;
    font-weight: 600;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 38px;
    -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.5);
    border: 2px solid #333f57;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    animation: cluster-animation 1.5s infinite;
}

.cluster-marker:hover {
    background: #808996;
}
