/**
 * Author: Md.Abdullah Al Mamun.
 * Email: dev.mamun@gmail.com
 * Date: Wednesday 21, Feb 2024
 * File: login.css
 * Dir: frontend/src/styles
 * Project: openbarcity
 */

.form-signin {
    width: 100%;
    max-width: 380px;
    padding: 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.tab-content {
    padding: 2rem;
    background: #fff;
    border-radius: 0 0 9px 9px;
    box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.02);
}

.nav-link {
    padding: 0.8rem 1.2rem;
    border: none;
}

.nav-link.active {
    background: #fff;
    border: none;
    font-weight: 500;
    color: #000 !important;
    border-radius: 9px 9px 0 0;
}

.nav-tabs {
    border-bottom: 0px solid #dee2e6;
}

/*.btn-primary {
    color: #fff !important;
    background-color: #0e0e0e !important;
    border-color: #0e0e0e !important;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: #0069d9 !important;
    border-color: #0062cc !important;
}*/

.tab-navs a {
    color: #8b8b8b;
}

.link-black {
    color: #000;
}
