.ant-form-item-explain {
    font-size: 12px;
    min-height: 12px;
    line-height: 16px;
    padding-top: 0;
    clear: both;
    color: #ff4d4f;
}

.width100 {
    width: 100%;
}

.line-break-anywhere {
    line-break: anywhere;
}

.alignleft {
    float: left;
}

.alignright {
    float: right;
}